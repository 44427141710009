import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
import banner from '../../../../assets/images/services-banner.jpg';

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        caption={
          <h2 className="x-large-heading color-white">
            Bespoke Business Services
          </h2>
        }
      />
      <ContentInner
        heading={'Bespoke Business Services'}
        bannerImage={banner}
        content={
          <p>
            As business owners ourselves, we are familiar with the passion
            people have for their business and the vision they have for where it
            can go. All too often, the day to day management details can get in
            the way of the bigger picture, and that’s where DGM can help.
            Whether it is managing cash flow, maximizing revenues or minimizing
            taxes, we can aid in the details that can slow down your operation.
            If you are looking to partner with a like-minded business, get a
            loan to grow, get a third-party valuation to sell or establish the
            most tax-efficient manner to transfer your business to someone else,
            we are able to offer our services and those of our trusted partners
            as well.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
